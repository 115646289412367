import { bootstrapLazy } from '@stencil/core';
import { patchEsm } from '@stencil/core/internal/client/patch-esm';
import { globalScripts } from '@stencil/core/internal/app-globals';
export const defineCustomElements = (win, options) => {
  if (typeof window === 'undefined') return Promise.resolve();
  return patchEsm().then(() => {
  globalScripts();
  return bootstrapLazy([/*!__STENCIL_LAZY_DATA__*/], options);
  });
};
